"use client"

// Imports - Node
import { twMerge } from "tailwind-merge"
import { useTheme } from "next-themes"

type Props = {
	nextTheme: string
	themeColor: string
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

function ThemeSwitcher({ nextTheme, themeColor, className, ...props }: Props) {
	const { setTheme } = useTheme()

	return (
		<button onClick={() => setTheme(nextTheme)} className={twMerge("relative size-6 rounded-full flex justify-center items-center", className)} {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.25} stroke="currentColor" className="size-5 stroke-foreground z-10">
				<path strokeLinecap="round" strokeLinejoin="round" d="M5 13L9 17L19 7" />
			</svg>
			<div className={twMerge("absolute size-full rounded-full transition-[background-color] ease-linear", themeColor)} />
		</button>
	)
}

// Exports
export default ThemeSwitcher
