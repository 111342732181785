type Props = {
	top?: boolean
	forceHide?: boolean
	tooltip: string
	children: React.ReactNode
}

function Tooltip({ top, forceHide, tooltip, children }: Props) {
	return (
		<div className={`group/tooltip relative rounded flex flex-col items-center outline-none outline-1 outline-offset-4 ${forceHide ? '' : "hover:outline-secondary delay-500"} transition-[outline-color] duration-300 ease-linear`}>
			{children}
			<div className={`absolute ${top ? "bottom-full -translate-y-2" : "top-full translate-y-2"} px-1 rounded border border-palette-5 opacity-0 ${forceHide ? "invisible" : "group-hover/tooltip:opacity-100 delay-500"} bg-palette-1 transition-opacity duration-200`}>
				<span className="text-xs whitespace-nowrap text-palette-foreground-2">
					{tooltip}
				</span>
			</div>
		</div>
	)
}

// Exports
export default Tooltip
