// Imports - Node
import { memo } from "react"

type Props = {}

const PartialRabbit = memo(function PartialRabbit({ }: Props): React.ReactNode {
	return (
		<div className="absolute bottom-4 left-4 max-md:mb-56 xl:opacity-0 xl:group-hover/footer:opacity-100 xl:transition-opacity xl:duration-500 -z-50">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 181" fill="currentColor" stroke="none" className="h-24 md:h-36 fill-palette-3 transition-[fill] ease-linear">
				<rect x="210.82" width="30.12" height="15.08" />
				<rect x="195.76" y="15.08" width="15.06" height="15.08" />
				<rect x="240.94" y="15.08" width="15.06" height="30.17" />
				<rect x="60.24" y="30.17" width="15.06" height="15.08" />
				<rect x="180.71" y="30.17" width="15.06" height="15.08" />
				<rect x="210.82" y="30.17" width="15.06" height="15.08" />
				<polygon points="60.24 15.08 60.24 30.17 45.18 30.17 45.18 45.25 30.12 45.25 30.12 60.33 15.06 60.33 15.06 45.25 0 45.25 0 15.08 15.06 15.08 15.06 0 45.18 0 45.18 15.08 60.24 15.08" />
				<rect x="45.18" y="45.25" width="15.06" height="15.08" />
				<rect x="195.76" y="45.25" width="15.06" height="15.08" />
				<rect x="225.88" y="45.25" width="15.06" height="15.08" />
				<rect x="30.12" y="60.33" width="15.06" height="15.08" />
				<rect x="210.82" y="60.33" width="15.06" height="15.08" />
				<polygon points="210.82 75.41 210.82 90.5 180.71 90.5 180.71 75.41 165.65 75.41 165.65 90.5 90.35 90.5 90.35 75.41 75.29 75.41 75.29 90.5 45.18 90.5 45.18 75.41 60.24 75.41 60.24 60.33 75.29 60.33 75.29 45.25 90.35 45.25 90.35 60.33 165.65 60.33 165.65 45.25 180.71 45.25 180.71 60.33 195.76 60.33 195.76 75.41 210.82 75.41" />
				<rect x="30.12" y="90.5" width="15.06" height="15.08" />
				<rect x="210.82" y="90.5" width="15.06" height="15.08" />
				<rect x="15.06" y="105.58" width="15.06" height="30.17" />
				<rect x="90.35" y="105.59" width="15.06" height="15.08" />
				<rect x="150.59" y="105.59" width="15.06" height="15.08" />
				<rect x="225.88" y="105.58" width="15.06" height="30.17" />
				<rect x="30.12" y="135.75" width="15.06" height="15.08" />
				<rect x="120.47" y="135.75" width="15.06" height="15.08" />
				<rect x="210.82" y="135.75" width="15.06" height="15.08" />
				<rect x="45.18" y="150.84" width="15.06" height="15.08" />
				<rect x="195.76" y="150.84" width="15.06" height="15.08" />
				<rect x="60.24" y="165.92" width="135.52" height="15.08" />
			</svg>
		</div>
	)
})

// Exports
export default PartialRabbit
