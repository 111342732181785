"use client"

// Imports - Node
import { useEffect } from "react"

const FALLBACK_ICON_PATH = "/assets/favicon/icon.svg"
const INACTIVE_ICON_PATH = "/assets/favicon/icon-inactive.svg"
const ICON_QUERY_SELECTOR = "link[rel=\"icon\"][sizes=\"any\"]"

type Props = {}

function Favicon({ }: Props): React.ReactNode {
	let icon_temp_path: string | null | undefined

	function handleVisibility() {
		const icon = document.querySelector(ICON_QUERY_SELECTOR)
		if (document.hidden) { icon?.setAttribute("href", INACTIVE_ICON_PATH) }
		else { icon?.setAttribute("href", icon_temp_path ?? FALLBACK_ICON_PATH) }
	}

	useEffect(() => {
		icon_temp_path = document.querySelector(ICON_QUERY_SELECTOR)?.getAttribute("href")

		document.addEventListener("visibilitychange", handleVisibility)
		return () => document.removeEventListener("visibilitychange", handleVisibility)
	}, [])

	return null
}

// Exports
export default Favicon
