"use client"

// Imports - Node
import { useState } from "react"
import { useTranslations } from "next-intl"
import { AnimatePresence, motion } from "framer-motion"

// Imports - Local
import Tooltip from "@/components/ui/tooltip"
import ThemeSwitcher from "@/components/theme-switcher"
import LocaleSwitcher from "@/components/locale-switcher"

// Import Types - Node
import type { Variants } from "framer-motion"

type Props = {}

function SettingsMenu({ }: Props) {
	const t = useTranslations("Components.Settings")
	const [active, setActive] = useState<boolean>(false)

	const variants: Variants = {
		initial: { opacity: 0 },
		animate: { opacity: 1, transition: { ease: "easeIn", duration: 0.2 } },
		exit: { scale: 0.95, opacity: 0, transition: { ease: "linear", duration: 0.3 } }
	}

	return (
		<div className="relative max-xl:hidden w-fit h-fit flex flex-col">
			<Tooltip forceHide={active} tooltip={t("tooltip")}>
				<button onClick={() => setActive(!active)} aria-label={t("tooltip")} className="p-1 rounded-md border border-palette-2 bg-palette-1 transition-[border-color,_background-color] ease-linear">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" strokeWidth={1.25} stroke="currentColor" className={`size-7 ${active ? "stroke-primary" : ''} transition-colors ease-linear`}>
						<path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
						<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
					</svg>
				</button>
			</Tooltip>
			<div className="absolute right-0 top-full w-44 md:w-52">
				<AnimatePresence>
					{active && (
						<motion.div variants={variants} initial="initial" animate="animate" exit="exit" transition={{ ease: "linear", duration: 0.3 }} className="w-full mt-4 p-2 rounded-md border border-palette-3 flex flex-col gap-y-0.5 bg-palette-2 transition-[border-color,_background-color] ease-linear">
							<div className="group/setting-row relative w-full p-1 rounded flex justify-between items-center hover:bg-palette-4 transition-colors ease-linear">
								<span className="ml-1 text-sm">
									{t("theme")}
								</span>
								<div className="flex items-center gap-x-2.5">
									<ThemeSwitcher nextTheme="light" themeColor="bg-[hsl(0,_0%,_96.1%)]" className="outline outline-1 outline-secondary dark:outline-transparent" />
									<ThemeSwitcher nextTheme="dark" themeColor="bg-[hsl(0,_0%,_3.9%)]" className="outline-transparent dark:outline dark:outline-1 dark:outline-secondary" />
								</div>
							</div>
							<div className="group/setting-row relative w-full p-1 rounded flex justify-between items-center hover:bg-palette-4 transition-colors ease-linear">
								<span className="ml-1 text-sm">
									{t("language")}
								</span>
								<div className="flex items-center gap-x-2.5">
									<LocaleSwitcher nextLocale="tr" />
									<LocaleSwitcher nextLocale="en" />
								</div>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	)
}

// Exports
export default SettingsMenu
