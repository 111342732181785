"use client"

// IMports - Node
// import { useParams } from "next/navigation"
import { useLocale } from "next-intl"
import { useTransition } from "react"

// Imports - Local
import { usePathname, useRouter } from "@/lib/i18n/routing"

// Import Types - Local
import type { Locale } from "@/lib/i18n/routing"

type Props = {
	nextLocale: Locale
}

function LocaleSwitcher({ nextLocale }: Props) {
	// const params = useParams()
	const locale = useLocale()
	const router = useRouter()
	const pathname = usePathname()
	const [isPending, startTransition] = useTransition()

	function handleClick(locale: Locale) { startTransition(() => router.replace({ pathname }, { locale })) }

	return (
		<button disabled={isPending} onClick={() => handleClick(nextLocale)} className="size-6 flex justify-center items-center">
			<div className="relative flex flex-col items-center">
				<span className="text-sm font-light uppercase">
					{nextLocale}
				</span>
				<div className={`absolute top-full ${locale === nextLocale ? "w-full" : ''} h-px bg-secondary`} />
			</div>
		</button>
	)
}

// Exports
export default LocaleSwitcher
